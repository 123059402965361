import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import ListGroup from 'react-bootstrap/ListGroup';
import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import "react-datepicker/dist/react-datepicker.css";
import * as Helper from './helper.js';
import ListGroupItem from 'react-bootstrap/esm/ListGroupItem';
import Day from './Day.js';
import moment from 'moment';
import Roboter from './roboter.svg';
import Cat from './cat.svg';

const Days = props =>  {
    const [schedule, setSchedule] = useState([
        {"displayName" : ''},
        {
            "from" : '-',
            "to" : '-',
            "availabilityViewInterval": 60
        },
        '',
        ]);
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(false);
    const [errorText, setErrorText] = useState('');    
    const [days, setDays] = useState([]);

    useEffect(() => {
        const getData = () => {
            axios.get(`https://calendarfreebusy.azurewebsites.net/api/HttpTrigger1?token=${props.visitorToken}&code=wzSFv5JWuE1O7qlLQouxqD7ipwLl4tGboMjKtsD71NBghKL/r8sCrQ==&from=${props.from}&to=${moment(props.from).add(props.days-1, 'days')}`)
            .then(res => {
                // console.log('res', res);
                // console.log('res.data[3].valid', res.data[3].valid);
                // console.log(res.data[2].match(/.{1,24}/g));
                if(res.data[3].valid) {
                    setDays(res.data[2].match(/.{1,24}/g));
                } else {
                    setError(true);
                    setErrorText('Fehler beim Zugriff. Das angegebene Token fehlt oder ist falsch. Bitte verwenden Sie ein Zugriffs-Token im Parameter "token" in der URL dieser Seite. Der korrekte Aufruf lautet beispielsweise: https://calendar.lamberti.at/?token=MEINTOKEN');
                }
                setLoaded(true);
            })
        }
        getData();
        
    }, [props.from, props.days]);

    return (
        <React.Fragment>            
        {         
            (loaded) ?
            <Fragment>
                {
                    (error) ?
                    <Alert variant='danger'>{errorText}</Alert>
                    :
                    <CardGroup>
                        { days.map((day, index) => <Day key={index} date={moment(props.from).add(index, 'days')} slots={day} fromHour={props.fromHour} toHour={props.toHour} interval={props.interval}></Day>)}
                    </CardGroup>
                }                        
            </Fragment>
                :
            <Row className="my-auto">
                <Col><div class="center"><object type="image/svg+xml" width="600" height="900"data={Cat} >svg-animation</object></div></Col>
                <Col ><div
                style={{
                    position: 'absolute', left: '25%', top: '25%',
                    transform: 'translate(-25%, -25%)'
                }}
                >
                <h1>Lade Kalender ...</h1></div></Col>
            </Row>
        }            
        </React.Fragment>
    )
}
export default Days;