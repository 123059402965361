export function niceDate(dateIn, expandToday, noTime) {
    const dateWork = new Date(dateIn);
    const dateNow = new Date();
    var ms = new Date().getTime() - 86400000;
    var dateYesterday = new Date(ms);
    
    var time = String(dateWork.getHours()).padStart(2, '0') + ':' + String(dateWork.getMinutes()).padStart(2, '0');
    var date = String(dateWork.getDate()).padStart(2, '0') + '.' + String((dateWork.getMonth()+1)).padStart(2, '0') + '.' + dateWork.getFullYear();
    var dateToday = String(dateNow.getDate()).padStart(2, '0') + '.' + String((dateNow.getMonth()+1)).padStart(2, '0') + '.' + dateNow.getFullYear();
    
    if (
      dateWork.getDate() == dateYesterday.getDate() &&
      dateWork.getMonth() == dateYesterday.getMonth() &&
      dateWork.getFullYear() == dateYesterday.getFullYear())
      {
        return 'Gestern ' + (noTime ? '' : time);
      }
    else if (date == dateToday)
      {
        if (expandToday)
        {
          return 'Heute ' + (noTime ? '' : time);
        }
        else
        {
          return time;
        }
      }
    else if (!dateIn)
      {
        return '';
      }
      else
      return date;
    }