import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import ListGroup from 'react-bootstrap/ListGroup';
import Tooltip from 'react-bootstrap/Tooltip';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import "react-datepicker/dist/react-datepicker.css";
import * as Helper from './helper.js';
import ListGroupItem from 'react-bootstrap/esm/ListGroupItem';
import Day from './Day.js';
import moment from 'moment';
import Roboter from './roboter.svg';
import HeatMap from '@uiw/react-heat-map';
import Cat from './cat.svg';

const CalendarHeatmap = props =>  {
    const [schedule, setSchedule] = useState([
        {"displayName" : ''},
        {
            "from" : '-',
            "to" : '-',
            "availabilityViewInterval": 60
        },
        '',
        ]);
    const [loaded, setLoaded] = useState(false);
    const [days, setDays] = useState([{}]);

    function buildDayList(days) {
      let newArr = [];    
      if(days) {
        days.map((slot, index) => {
          let numberOfBusy = 0;
          Array.from(slot).map((hour, idx) => {
            if(hour == '1' || hour == '2' || hour == '3' || hour == '4') { numberOfBusy++ }
          });
  
          let day = {
            "date": moment(new Date(props.from)).add(index, 'days').format('YYYY/MM/DD'),
            "count": numberOfBusy
          };
          newArr.push(day);
        });
      }
      console.log('newArr', newArr);
      return newArr     
    }

    function selectDate(data) {
      console.log('here dataxx', data);
      //setClickedDate(data.date);
      // setClickedDateCount(data.count);
    }

    useEffect(() => {
        const getData = () => {
            axios.get(`https://calendarfreebusy.azurewebsites.net/api/HttpTrigger1?token=${props.visitorToken}&code=wzSFv5JWuE1O7qlLQouxqD7ipwLl4tGboMjKtsD71NBghKL/r8sCrQ==&from=${props.from}&to=${moment(props.from).add(61, 'days')}`)
            .then(res => {
                setDays(buildDayList(res.data[2].match(/.{1,24}/g)));
                setLoaded(true);
            })
        }
        getData();
        
    }, [props.from, props.days]);

    return (
        <React.Fragment>
        {         
            (loaded) ?
            <React.Fragment>
            <HeatMap 
              value={days} 
              startDate={new Date()} 
              endDate={new Date(moment(new Date()).add(4, 'months'))}
              weekLables={['Son', 'Mon', 'Die', 'Mit', 'Don', 'Fri', 'Sam']}
              // panelColors={{ 0: '#EBEDF0', 8: '#7BC96F', 4: '#C6E48B', 12: '#239A3B', 32: '#196127' }}
              // #EBEDF0 = grau
              // #C3E6CB = hellgrün
              // #F5C6CB = hellrot
              // #DC3545 = tiefrot 
              // 
              panelColors={{ 0: '#EBEDF0', 1: '#C3E6CB', 10: '#F5C6CB', 12: '#DC3545' }}
              legendCellSize={0}
              rectRender={(props, data) => {

              }}
              />                
              </React.Fragment>
              
                :
            <Row className="align-items-center">
                <Col><div class="center"><h1></h1></div></Col>
            </Row>
        }            
        </React.Fragment>
    )
}
export default CalendarHeatmap;