import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Days from './Days.js';
import * as Helper from './helper.js';
import moment from 'moment';
import CalendarHeatmap from './CalendarHeatmap';


const Availability = props =>  {  
  const [fromSelector, setFromSelector] = useState(new Date());

  function handleChangeDate(date) {
    setFromSelector(date);    
  }

  let interval = 60;
  let fromHour = 8;
  let toHour = 20;
  let visitorToken = (new URLSearchParams(window.location.search)).get("token");

  return (
  <Container>
    <div class="center">
    <p></p>
    Verfügbarkeiten ab:
    <p></p>
    <DatePicker
                  todayButton="Heute"
                  selected={fromSelector}
                  onChange={date => handleChangeDate(date)}
                  dateFormat="dd.MM.yyyy"
                  className="form-control"                  
                  isClearable
                  showWeekNumbers
                  calendarStartDay={1}
                  minDate={new Date()}
                  maxDate={ new Date(moment(new Date()).add(28, 'days'))}
                  />    
    <p></p>
    </div>
        
    <Days from={fromSelector} days={7} interval={interval} fromHour={fromHour} toHour={toHour} visitorToken={visitorToken}></Days>
    <CalendarHeatmap from={fromSelector} visitorToken={visitorToken}></CalendarHeatmap>
  </Container>
  )  
}
export default Availability;