import React, { useState, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import "react-datepicker/dist/react-datepicker.css";
import * as Helper from './helper.js';
import moment from 'moment';

function status2color(status) {
  // from graphi api
  // 0= free
  // 1= tentative
  // 2= busy
  // 3= out of office
  // 4= working elsewhere
  // manual addition for out of working hours
  // 9 = out of hours
  switch (status) {
    case '0':
      return 'success';
    case '1':
      return 'danger';
    case '2':
      return 'danger';
    case '3':
      return 'danger';
    default:
      return 'danger';
  }
}

let weekDays = [
  'Sonntag',
  'Montag',
  'Dienstag',
  'Mittwoch',
  'Donnerstag',
  'Freitag',
  'Samstag'
];

const FreeBusy = props => {
  let from = moment(props.date);
  from.set({hour: props.from,minute:0,second:0,millisecond:0});
  let to = moment(from).add(props.interval, 'minutes');
  return (
    <React.Fragment>
      {from.hours()}:00 - {to.hours()}:00
    </React.Fragment>
  )
}

const Day = props =>  {
  let slots = Array.from(props.slots);
  let newArr = [];
  slots.map((slot, index) => {
    if(index < props.fromHour || index > props.toHour-1) {
      newArr.push('9');
    } else {
      newArr.push(slot);
    }
  });
  slots = newArr;

  return (
  <React.Fragment>
    <Card key={props.date}>
        <Card.Header>
            <b>{weekDays[moment(props.date).day()]}</b><br></br>
            {Helper.niceDate(props.date, true, true)}
        </Card.Header>
        <ListGroup variant="flush" key='group'>
          { 
            slots.map((slot, index) => { 
            return slot != '9' ?
            <ListGroup.Item variant={status2color(slot)} key={index}><FreeBusy key={index} date={props.date} index={index} slot={slot} from={index} interval={props.interval}></FreeBusy></ListGroup.Item> 
            :
            <React.Fragment></React.Fragment>
            })
          }
        </ListGroup>
    </Card>
  </React.Fragment>
  )  
}
  export default Day;