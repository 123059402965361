import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Availability from './Availability.js';

function App() {
  return (
    <div className="App">
      <Availability></Availability>
    </div>
  );
}

export default App;
